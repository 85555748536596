/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.first-button').on('click', function () {
          $('.animated-icon1').toggleClass('open');
        });
        $('.second-button').on('click', function () {
          $('.animated-icon2').toggleClass('open');
        });
        $('.third-button').on('click', function () {
          $('.animated-icon3').toggleClass('open');
        });


        /*
        $("#header .navbar .menu-item-has-children").hover(
          function () {
            $("#header .navbar .menu-item-has-children .dropdown-menu").addClass("show");
          },
          function () {
            $("#header .navbar .menu-item-has-children .dropdown-menu").removeClass("show");
          }
        );
        */  

        $('#search-icon').on('click', function () {
          $('#search-form').toggleClass('open');  
          //$('#search-form').toggle();
        });






        /*
         * For Navbar.Responsive
        */
        var defaults={
            sm : 540,
            md : 720,
            lg : 960,
            xl : 1140,
            navbar_expand: 'lg',
            animation: true,
            animateIn: 'fadeIn',
        };
        $.fn.bootnavbar = function(options) {

            var screen_width = $(document).width();
            settings = $.extend(defaults, options);

            if(screen_width >= settings.lg){
                $(this).find('.dropdown').hover(function() {
                    $(this).addClass('show');
                    $(this).find('.dropdown-menu').first().addClass('show');
                    if(settings.animation){
                        $(this).find('.dropdown-menu').first().addClass('animated ' + settings.animateIn);
                    }
                }, function() {
                    $(this).removeClass('show');
                    $(this).find('.dropdown-menu').first().removeClass('show');
                });
            }

            $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
              if (!$(this).next().hasClass('show')) {
                $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
              }
              var $subMenu = $(this).next(".dropdown-menu");
              $subMenu.toggleClass('show');

              $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
                $('.dropdown-submenu .show').removeClass("show");
              });

              return false;
            });
        };


        $('#main_navbar').bootnavbar();




         /*
           * For Jquery.EqualHeight
          */
          function updateViewportDimensions() {
              var w=window,d=document,e=d.documentElement,g=d.getElementsByTagName('body')[0],x=w.innerWidth||e.clientWidth||g.clientWidth,y=w.innerHeight||e.clientHeight||g.clientHeight;
              return { width:x,height:y };
          }
          // setting the viewport width
          var viewport = updateViewportDimensions();
          var waitForFinalEvent = (function () {
              var timers = {};
              return function (callback, ms, uniqueId) {
                  if (!uniqueId) { uniqueId = "Don't call this twice without a uniqueId"; }
                  if (timers[uniqueId]) { clearTimeout (timers[uniqueId]); }
                  timers[uniqueId] = setTimeout(callback, ms);
              };
          })();
          
          var timeToWaitForLast = 100; // how long to wait before deciding the resize has stopped, in ms. Around 50-100 should work ok.
          
          /*
           * Set the same height for elements
          */
          $.fn.equalHeights = function(per_row) {
              var currentTallest  = 0,
                  $this           = $(this);

              function mapCallback(item) {
                  currentTallest  = 0;
                  $(this).css({'min-height': '1px'});
                  if ($(this).outerHeight() > currentTallest) {
                      currentTallest = $(this).outerHeight();
                  } 
                  return {
                      currentTallest : item[0],
                  };
              } 

              if (per_row) {
                  for (var i=0; i<$this.length; i=i+per_row) {
                      currentTallest  = 0;
                      $this.slice(i, i+per_row).each(mapCallback);
                      $this.slice(i, i+per_row).css({'min-height': currentTallest});
                  }
              } else {
                  $this.each(function(){
                      $(this).css({'min-height': '1px'});
                      if ($(this).outerHeight() > currentTallest) {
                          currentTallest = $(this).outerHeight();
                      }
                  });
              
                  $this.css({'min-height': currentTallest});
              }
              return this;     
          };

          /*
           * Put all your regular jQuery in here.
          */
          $('#related .item .desc').equalHeights();
          $('.equalHeights-1').equalHeights();
          $('.equalHeights-2').equalHeights();
          $('.equalHeights-3').equalHeights();
           
          // Fire needed update on window resize
          $(window).resize(function () {   
            waitForFinalEvent( function() {
              $('#related .item .desc').equalHeights();
              $('.equalHeights-1').equalHeights();
              $('.equalHeights-2').equalHeights();
              $('.equalHeights-3').equalHeights();
            }, timeToWaitForLast, "homepage_update_fn");
          });

          
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
